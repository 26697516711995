import React, { useEffect, useState } from 'react';
import YearlyStatistics from './inscription/YearlyStatistics';
import TotalByLevelStatistics from './inscription/TotalByLevelStatistics';
import { Col, DatePicker, Row } from 'antd';
import FinanceCards from './club/FinanceCards';
import { Cards } from '../../components/cards/frame/cards-frame';
import FinanceCharges from './club/FinanceCharges';
import TotalPaiment from './transport/TotalPaiment';
import image1 from '../../static/svg/4.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  viewFinanceTransportStatisticsData,
  viewYearlyInscriptionByLevelStatisticsData,
  viewYearlyInscriptionStatisticsData,
} from '../../redux/statistics/actionCreator';
import moment from 'moment';

const { RangePicker } = DatePicker;

const InscriptionsStatistics = () => {
  const dispatch = useDispatch();



  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.financeTransport,
    };
  });

  const [dates, setDates] = useState([moment().subtract(1, 'year'), moment()]);

  const handleChange = (values) => {
    setDates(values);
  };

  useEffect(() => {
    const startDate = dates[0]?.format('MM-YYYY');
    const endDate = dates[1]?.format('MM-YYYY');
    dispatch(viewYearlyInscriptionStatisticsData([startDate, endDate]));
    dispatch(viewYearlyInscriptionByLevelStatisticsData([startDate, endDate]));
    dispatch(viewFinanceTransportStatisticsData([startDate, endDate]));

  }, [dates]);
  return (
    <div
      style={{
        margin: '25px',
      }}
    >
      <Cards
        headless
        style={{
          backgroundColor: '#FF6B6B',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <span style={{ fontSizer: '28px', fontHeight: 700 }}>Statistiques d'Inscription</span>
      </Cards>
      <Row gutter={25}>
        <Col xxl={24} lg={24} sm={24} xs={24}>
          <RangePicker
            style={{ height: '37px', marginBottom: '20px' }}
            picker="month"
            defaultValue={[moment().subtract(1, 'year'), moment()]}
            onChange={handleChange}
          />
        </Col>
        <Col xxl={14} lg={14} sm={14} xs={24}>
          <YearlyStatistics dates={dates} />
        </Col>
        <Col xxl={10} lg={10} sm={10} xs={24}>
          <TotalByLevelStatistics dates={dates} />
        </Col>
      </Row>
      <Cards
        headless
        style={{
          backgroundColor: '#FF6B6B',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <span style={{ fontSizer: '28px', fontHeight: 700 }}>Statistiques Financières</span>
      </Cards>
      <FinanceCards />
      <FinanceCharges />{' '}
      <Cards
        headless
        style={{
          backgroundColor: '#FF6B6B',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <span style={{ fontSizer: '28px', fontHeight: 700 }}>Statistiques du Transport</span>
      </Cards>
      <Row gutter={25}>
        <Col xxl={9} lg={9} sm={9} xs={24}>
          <TotalPaiment
            values={[statistics && statistics.gainPrevisionnelTransport, statistics && statistics.gainReelTransport]}
          />
        </Col>
        <Col xxl={5} lg={5} sm={5} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '27vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '20px',
              }}
            >
              <img src={image1} />
              <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total charge par mois</span>
              <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                {statistics && statistics.totalCharge}
              </span>
            </div>
          </Cards>
        </Col>
        <Col xxl={5} lg={5} sm={5} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '27vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '20px',
              }}
            >
              <img src={image1} />
              <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Élèves transportés</span>
              <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                {statistics && statistics.elevesTransportesQuotidiennement}
              </span>
            </div>
          </Cards>
        </Col>
        <Col xxl={5} lg={5} sm={5} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '27vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '20px',
              }}
            >
              <img src={image1} />
              <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total Paymenet</span>
              <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                {' '}
                {statistics && statistics.totalPaimenet}
              </span>
            </div>
          </Cards>
        </Col>
      </Row>
    </div>
  );
};

export default InscriptionsStatistics;
