import { Col, DatePicker, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import image1 from '../../../static/svg/4.svg';
import image2 from '../../../static/svg/5.svg';
import image3 from '../../../static/svg/6.svg';
import {
  viewFinanceChargeStatisticsData,
  viewFinanceOverviewStatisticsData,
} from '../../../redux/statistics/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';

const { RangePicker } = DatePicker;

function FinanceCards() {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([moment().subtract(1, 'year'), moment()]);

  const handleChange = (values) => {
    setDates(values);
  };

  useEffect(() => {
    const startDate = dates[0]?.format('MM-YYYY');
    const endDate = dates[1]?.format('MM-YYYY');
    dispatch(viewFinanceOverviewStatisticsData([startDate, endDate]));
    dispatch(viewFinanceChargeStatisticsData([startDate, endDate]));
  }, [dates]);

  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.financeOverview,
    };
  });

  return (
    <>
      <RangePicker
        style={{ height: '37px', marginBottom: '20px' }}
        picker="month"
        defaultValue={[moment().subtract(1, 'year'), moment()]}
        onChange={handleChange}
      />
      <br />
      <br />
      <Row gutter={25}>
        <Col xxl={5} lg={5} sm={5} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '20vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '20px',
              }}
            >
              <img src={image1} />
              <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total encaissement logique</span>
              <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                {statistics && statistics.totalEncaissement}
              </span>
            </div>
          </Cards>
        </Col>
        <Col xxl={5} lg={5} sm={5} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '20vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '20px',
              }}
            >
              <img src={image2} />
              <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total paiement parent</span>
              <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                {statistics && statistics.totalPaiementParent}
              </span>
            </div>
          </Cards>
        </Col>
        <Col xxl={5} lg={5} sm={5} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '20vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '20px',
              }}
            >
              <img src={image3} />
              <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total impayé</span>
              <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                {statistics && statistics.totalImpayer}
              </span>
            </div>
          </Cards>
        </Col>
        <Col xxl={9} lg={9} sm={9} xs={24}>
          <Cards headless>
            <div
              style={{
                height: '20vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <PercentageDoughnutChart
                value={parseFloat(statistics ? statistics.tauxRecouvrement : 0)}
                total={100}
                color={'#00B074'}
                hoverColoer={'#C5DFEA'}
                title={'Taux de recouvrement'}
              />
              <PercentageDoughnutChart
                value={parseFloat(statistics ? statistics.tauxImpayer : 0)}
                total={100}
                color={'#FF5B5B'}
                hoverColoer={'#FDCECE'}
                title={"Taux d'impayés"}
              />
            </div>
          </Cards>
        </Col>
      </Row>
    </>
  );
}

const PercentageDoughnutChart = ({ value, total, color, hoverColor, title }) => {
  const percentage = (value / total) * 100;

  const data = {
    labels: ['Value', 'Remaining'],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [color, '#dddddd'],
        hoverBackgroundColor: [color, '#dddddd'],
        borderRadius: 5,
        spacing: 3,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      title: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        width: '20vh',
        height: '17vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
      }}
    >
      <Doughnut data={data} options={options} />
      <div style={{ color: 'black', marginTop: '10px' }}>{title}</div>
    </div>
  );
};

export default FinanceCards;
