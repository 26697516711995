import React, { useEffect, useState } from 'react';
import { Modal, Select, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { viewRankStatisticsData } from '../../../redux/statistics/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';

const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    width: '30%',
  },
  {
    title: 'Nom',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Average Grade',
    dataIndex: 'average_grade',
    key: 'average_grade',
    width: '20%',
    render: (text) => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            color: text < 10 ? '#F32121' : '#4DC591',
            backgroundColor: text < 10 ? '#F321211e' : '#4DC5911e',
            padding: '5px 10px',
            borderRadius: '5px',
          }}
        >
          {text.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    ),
  },
];

const RankByClass = ({dates}) => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [classes, setClasses] = useState([]);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.ranks,
    };
  });

  useEffect(() => {
    const startDate = dates[0]?.format('MM-YYYY');
    const endDate = dates[1]?.format('MM-YYYY');
    dispatch(viewRankStatisticsData([startDate, endDate]));
  }, [dates]);

  useEffect(() => {
    if (statistics) {
      const classesData = statistics.map((stats) => stats.class);
      setClasses(classesData);
      setSelectedClass(classes[0]);
    }
  }, [statistics]);

  useEffect(() => {
    if (selectedClass) {
      handleChangeData();
    }
  }, [selectedClass]);

  const handleChangeData = () => {
    const foundClass = statistics.find((item) => item.class == selectedClass);
    const foundStudents = foundClass ? foundClass.students : [];
    const traitedData = foundStudents
      .map((student) => ({
        ...student,
        name: `${student.prenom} ${student.nom}`,
        rank: student.rank[0],
      }))
      .slice(0, 5);
    console.log(foundClass);

    setData(foundClass ? traitedData : []);
  };

  return (
    <Cards headless>
      <div
        style={{
          height: '50vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: '500',
            marginBottom: '10px',
            color: '#333',
          }}
        >
          <div>Classement des élèves par classe</div>
          <Select
            placeholder="Class"
            value={selectedClass}
            onChange={(e) => setSelectedClass(e)}
            style={{ height: '40px' }}
            options={classes.map((item) => ({ value: item, label: item }))}
          />
        </div>
        <Table columns={columns} dataSource={data} pagination={false} showHeader={false} />
      </div>
    </Cards>
  );
};

export default RankByClass;
