import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import image1 from '../../../static/svg/7.svg';
import image2 from '../../../static/svg/8.svg';
import image3 from '../../../static/svg/9.svg';
import image4 from '../../../static/svg/10.svg';
import { viewFinanceChargeStatisticsData } from '../../../redux/statistics/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import CustomBarChart from '../CustomBarChart';

function FinanceCharges() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewFinanceChargeStatisticsData());
  }, []);

  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.financeCharge,
    };
  });

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    if (statistics) {
      const resultObject = {};

      statistics.totalChargesByType.forEach((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        resultObject[key] = value;
      });
      const keys = Object.keys(resultObject);
      const values = Object.values(resultObject).map((key) => parseFloat(key));

      setLabels(keys);
      setDatasets(values);
    }
  }, [statistics]);

  return (
    <Row gutter={25}>
      <Col xxl={14} lg={14} sm={14} xs={24}>
        <Cards headless>
          <div
            style={{
              height: '49.5vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
              }}
            >
              Total charge par rubrique{' '}
            </div>
            <CustomBarChart labels={labels} datasets={datasets} title="" beginAtZero={true} />
          </div>
        </Cards>
      </Col>
      <Col xxl={10} lg={10} sm={10} xs={24}>
        <Row gutter={25}>
          <Col xxl={12} lg={12} sm={12} xs={24}>
            <Cards headless>
              <div
                style={{
                  height: '20vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rowGap: '20px',
                }}
              >
                <img src={image1} />
                <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total charge</span>
                <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                  {statistics && statistics.totalCharge}
                </span>
              </div>
            </Cards>
          </Col>
          <Col xxl={12} lg={12} sm={12} xs={24}>
            <Cards headless>
              <div
                style={{
                  height: '20vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rowGap: '20px',
                }}
              >
                <img src={image2} />
                <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total charge salariale</span>
                <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                  {statistics && statistics.totalChargeSalarial}
                </span>
              </div>
            </Cards>
          </Col>
          <Col xxl={12} lg={12} sm={12} xs={24}>
            <Cards headless>
              <div
                style={{
                  height: '20vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rowGap: '20px',
                }}
              >
                <img src={image3} />
                <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total charge payée</span>
                <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                  {statistics && statistics.totalChargePayer}
                </span>
              </div>
            </Cards>
          </Col>
          <Col xxl={12} lg={12} sm={12} xs={24}>
            <Cards headless>
              <div
                style={{
                  height: '20vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rowGap: '20px',
                }}
              >
                <img src={image4} />
                <span style={{ fontWeight: 300, fontSize: '14px', textAlign: 'center' }}>Total charge non payée</span>
                <span style={{ fontWeight: 700, fontSize: '20px', color: 'black' }}>
                  {statistics && statistics.totalChargeNonPayer}
                </span>
              </div>
            </Cards>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FinanceCharges;
