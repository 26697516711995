import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewAvreageGradesStatisticsData } from '../../../redux/statistics/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';
import CustomBarChart from '../CustomBarChart';
import { Select } from 'antd';

const ClassStatistics = ({ dates }) => {
  const dispatch = useDispatch();
  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.grades,
    };
  });

  useEffect(() => {
    const startDate = dates[0]?.format('MM-YYYY');
    const endDate = dates[1]?.format('MM-YYYY');
    dispatch(viewAvreageGradesStatisticsData([startDate, endDate]));
  }, [dates]);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [niveau, setNiveau] = useState('1');

  useEffect(() => {
    if (statistics) {
      handleChangeData();
    }
  }, [statistics, niveau]);

  const handleChangeData = () => {
    console.log(statistics);

    const keys = statistics.map((item) => item.class);
    const values = statistics.map((item) => item.average_grade);

    setLabels(keys);
    setDatasets(values);
  };

  return (
    <>
      {statistics && (
        <Cards headless>
          <div
            style={{
              height: '50vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
              }}
            >
              <div>Moyenne generale des classes</div>
              <Select
                placeholder="Niveau"
                value={niveau}
                onChange={(e) => setNiveau(e)}
                style={{ height: '40px' }}
                options={[
                  { value: '1', label: '1ère année' },
                  { value: '2', label: '2ème année' },
                  { value: '3', label: '3ème année' },
                  { value: '4', label: '4ème année' },
                  { value: '5', label: '5ème année' },
                  { value: '6', label: '6ème année' },
                ]}
              />
            </div>
            <div style={{ height: '40vh', display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
              <CustomBarChart
                labels={labels}
                datasets={datasets}
                title="Inscriptions by Month for 2024"
                beginAtZero={true}
              />
            </div>
          </div>
        </Cards>
      )}
    </>
  );
};

export default ClassStatistics;
