import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  viewYearlyInscriptionStatisticsBegin,
  viewYearlyInscriptionStatisticsSuccess,
  viewYearlyInscriptionStatisticsErr,

  viewYearlyInscriptionByLevelStatisticsBegin,
  viewYearlyInscriptionByLevelStatisticsSuccess,
  viewYearlyInscriptionByLevelStatisticsErr,

  viewMonthlyInscriptionStatisticsBegin,
  viewMonthlyInscriptionStatisticsSuccess,
  viewMonthlyInscriptionStatisticsErr,

  viewClubInscriptionStatisticsBegin,
  viewClubInscriptionStatisticsSuccess,
  viewClubInscriptionStatisticsErr,

  viewTotalClubInscriptionStatisticsBegin,
  viewTotalClubInscriptionStatisticsSuccess,
  viewTotalClubInscriptionStatisticsErr,

  viewTotalGainPrevisionnelsClubStatisticsBegin,
  viewTotalGainPrevisionnelsClubStatisticsSuccess,
  viewTotalGainPrevisionnelsClubStatisticsErr,

  viewTotalGainRealisesClubStatisticsBegin,
  viewTotalGainRealisesClubStatisticsSuccess,
  viewTotalGainRealisesClubStatisticsErr,

  viewClubDepenseStatisticsBegin,
  viewClubDepenseStatisticsSuccess,
  viewClubDepenseStatisticsErr,

  viewAvreageGradesPerLevelStatisticsBegin,
  viewAvreageGradesPerLevelStatisticsSuccess,
  viewAvreageGradesPerLevelStatisticsErr,

  viewAvreageGradesStatisticsBegin,
  viewAvreageGradesStatisticsSuccess,
  viewAvreageGradesStatisticsErr,

  viewRankPerLevelStatisticsBegin,
  viewRankPerLevelStatisticsSuccess,
  viewRankPerLevelStatisticsErr,

  viewRankStatisticsBegin,
  viewRankStatisticsSuccess,
  viewRankStatisticsErr,

  viewFinanceOverviewStatisticsBegin,
  viewFinanceOverviewStatisticsSuccess,
  viewFinanceOverviewStatisticsErr,

  viewFinanceChargeStatisticsBegin,
  viewFinanceChargeStatisticsSuccess,
  viewFinanceChargeStatisticsErr,

  viewFieldSuccesRateStatisticsBegin,
  viewFieldSuccesRateStatisticsSuccess,
  viewFieldSuccesRateStatisticsErr,

  viewLevelFieldsSuccesRateStatisticsBegin,
  viewLevelFieldsSuccesRateStatisticsSuccess,
  viewLevelFieldsSuccesRateStatisticsErr,

  viewFinanceTransportStatisticsBegin,
  viewFinanceTransportStatisticsSuccess,
  viewFinanceTransportStatisticsErr,
} = actions;

const viewYearlyInscriptionStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewYearlyInscriptionStatisticsBegin());
    try {
      console.log(dates);

      const response = await DataService.get(
        `/statistique/inscriptions/ByYearAndClasse?startDate=${dates[0]}&endDate=${dates[1]}`,
      );
      if (response.status === 200) {
        dispatch(viewYearlyInscriptionStatisticsSuccess(response.data));
      } else {
        dispatch(viewYearlyInscriptionStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewYearlyInscriptionStatisticsErr('err'));
    }
  };
};

const viewYearlyInscriptionByLevelStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewYearlyInscriptionByLevelStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/inscriptions/ByYearAndNiveau?startDate=${dates[0]}&endDate=${dates[1]}`,
      );
      if (response.status === 200) {
        const data = Object.entries(response.data).map(([key, value]) => ({ [`${key}`]: value.total }));
        dispatch(viewYearlyInscriptionByLevelStatisticsSuccess(data));
      } else {
        dispatch(viewYearlyInscriptionByLevelStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewYearlyInscriptionByLevelStatisticsErr('err'));
    }
  };
};

const viewMonthlyInscriptionStatisticsData = (dates, month) => {
  return async (dispatch) => {
    dispatch(viewMonthlyInscriptionStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/inscriptions/monthly/${dates}/${month}`);
      if (response.status === 200) {
        dispatch(viewMonthlyInscriptionStatisticsSuccess(response.data));
      } else {
        dispatch(viewMonthlyInscriptionStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewMonthlyInscriptionStatisticsErr('err'));
    }
  };
};

const viewClubInscriptionStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewClubInscriptionStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/inscriptions-par-club?startDate=${dates[0]}&endDate=${dates[1]}`,
      );
      if (response.status === 200) {
        dispatch(viewClubInscriptionStatisticsSuccess(response.data));
      } else {
        dispatch(viewClubInscriptionStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewClubInscriptionStatisticsErr('err'));
    }
  };
};

const viewTotalClubInscriptionStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewTotalClubInscriptionStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/inscriptions-totales?startDate=${dates[0]}&endDate=${dates[1]}`,
      );

      if (response.status === 200) {
        dispatch(viewTotalClubInscriptionStatisticsSuccess(response.data.total_inscriptions));
      } else {
        dispatch(viewTotalClubInscriptionStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewTotalClubInscriptionStatisticsErr('err'));
    }
  };
};

const viewTotalGainPrevisionnelsClubStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewTotalGainPrevisionnelsClubStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/gains-previsionnels?startDate=${dates[0]}&endDate=${dates[1]}`,
      );

      if (response.status === 200) {
        dispatch(viewTotalGainPrevisionnelsClubStatisticsSuccess(response.data.gains_previsionnels));
      } else {
        dispatch(viewTotalGainPrevisionnelsClubStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewTotalGainPrevisionnelsClubStatisticsErr('err'));
    }
  };
};

const viewTotalGainRealisesClubStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewTotalGainRealisesClubStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/gains-realises?startDate=${dates[0]}&endDate=${dates[1]}`);

      if (response.status === 200) {
        dispatch(viewTotalGainRealisesClubStatisticsSuccess(response.data.gains_realisés));
      } else {
        dispatch(viewTotalGainRealisesClubStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewTotalGainRealisesClubStatisticsErr('err'));
    }
  };
};

const viewClubDepenseStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewClubDepenseStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/gains-depenses-par-club?startDate=${dates[0]}&endDate=${dates[1]}`,
      );
      if (response.status === 200) {
        dispatch(viewClubDepenseStatisticsSuccess(response.data));
      } else {
        dispatch(viewClubDepenseStatisticsErr('err'));
      }
    } catch (err) {
      dispatch(viewClubDepenseStatisticsErr('err'));
    }
  };
};

const viewAvreageGradesPerLevelStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewAvreageGradesPerLevelStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/average-grades-level?startDate=${dates[0]}&endDate=${dates[1]}`,
      );

      dispatch(viewAvreageGradesPerLevelStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewAvreageGradesPerLevelStatisticsErr('err'));
    }
  };
};

const viewAvreageGradesStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewAvreageGradesStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/average-grades?startDate=${dates[0]}&endDate=${dates[1]}`);

      dispatch(viewAvreageGradesStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewAvreageGradesStatisticsErr('err'));
    }
  };
};

const viewRankPerLevelStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewRankPerLevelStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/rank-students-level?startDate=${dates[0]}&endDate=${dates[1]}`,
      );
      dispatch(viewRankPerLevelStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewRankPerLevelStatisticsErr('err'));
    }
  };
};
const viewRankStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewRankStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/rank-students?startDate=${dates[0]}&endDate=${dates[1]}`);
      dispatch(viewRankStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewRankStatisticsErr('err'));
    }
  };
};

const viewFinanceOverviewStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewFinanceOverviewStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/financial-organisation?startDate=${dates[0]}&endDate=${dates[1]}`,
      );

      dispatch(viewFinanceOverviewStatisticsSuccess(response.data.payload));
    } catch (err) {
      dispatch(viewFinanceOverviewStatisticsErr('err'));
    }
  };
};

const viewFinanceChargeStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewFinanceChargeStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/financial-charge?startDate=${dates[0]}&endDate=${dates[1]}`);

      dispatch(viewFinanceChargeStatisticsSuccess(response.data.payload));
    } catch (err) {
      dispatch(viewFinanceChargeStatisticsErr('err'));
    }
  };
};

const viewFieldSuccesRateStatisticsData = (class_id) => {
  return async (dispatch) => {
    dispatch(viewFieldSuccesRateStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/success-rate/${class_id}`);
      dispatch(viewFieldSuccesRateStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewFieldSuccesRateStatisticsErr('err'));
    }
  };
};

const viewLevelFieldsSuccesRateStatisticsData = (matiere) => {
  return async (dispatch) => {
    dispatch(viewLevelFieldsSuccesRateStatisticsBegin());
    try {
      const response = await DataService.get(`/statistique/success-rate-level/${matiere}`);
      dispatch(viewLevelFieldsSuccesRateStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewLevelFieldsSuccesRateStatisticsErr('err'));
    }
  };
};

const viewFinanceTransportStatisticsData = (dates) => {
  return async (dispatch) => {
    dispatch(viewFinanceTransportStatisticsBegin());
    try {
      const response = await DataService.get(
        `/statistique/financial-transport?startDate=${dates[0]}&endDate=${dates[1]}`,
      );

      dispatch(viewFinanceTransportStatisticsSuccess(response.data));
    } catch (err) {
      dispatch(viewFinanceTransportStatisticsErr('err'));
    }
  };
};

export {
  viewYearlyInscriptionStatisticsData,
  viewYearlyInscriptionByLevelStatisticsData,
  viewMonthlyInscriptionStatisticsData,
  viewClubInscriptionStatisticsData,
  viewAvreageGradesPerLevelStatisticsData,
  viewAvreageGradesStatisticsData,
  viewClubDepenseStatisticsData,
  viewTotalClubInscriptionStatisticsData,
  viewTotalGainPrevisionnelsClubStatisticsData,
  viewTotalGainRealisesClubStatisticsData,
  viewFinanceOverviewStatisticsData,
  viewRankPerLevelStatisticsData,
  viewRankStatisticsData,
  viewFinanceChargeStatisticsData,
  viewFieldSuccesRateStatisticsData,
  viewLevelFieldsSuccesRateStatisticsData,
  viewFinanceTransportStatisticsData,
};
