import React, { useEffect, useState } from 'react';
import CustomDoughnutChart from '../CustomDoughnutChart';
import { useSelector } from 'react-redux';
import { Cards } from '../../../components/cards/frame/cards-frame';

const TotalByLevelStatistics = ({ dates }) => {
  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.yearlyInscriptionsByLevel,
    };
  });

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const handleYearChange = () => {
    if (statistics) {
      console.log(statistics);

      const keys = Object.keys(statistics);
      const values = statistics.map((obj) => Object.values(obj)[0]);

      setLabels(keys);
      setDatasets(values);
    }
  };

  useEffect(() => {
    handleYearChange();
  }, [statistics]);

  return (
    <>
      {
        <Cards headless>
          <div
            style={{
              height: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: '500',
                marginBottom: '10px',
                color: '#333',
              }}
            >
              Nombre d'élèves inscrits par niveau{' '}
            </div>

            <div className="calendar-header"></div>
            <CustomToolbar />
            <div
              style={{
                height: '35vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <CustomDoughnutChart
                labels={labels}
                datasets={datasets}
                title="Inscriptions by Month for 2024"
                beginAtZero={true}
              />
            </div>
          </div>
        </Cards>
      }
    </>
  );
};
const CustomToolbar = ({}) => {
  return <></>;
};
export default TotalByLevelStatistics;
