import actions from './actions';

const initialState = {
  arrayData: [],
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_DEVOIR_BEGIN,
  VIEW_DEVOIR_SUCCESS,
  VIEW_DEVOIR_ERR,

  VIEW_SINGLE_DEVOIR_BEGIN,
  VIEW_SINGLE_DEVOIR_SUCCESS,
  VIEW_SINGLE_DEVOIR_ERR,

  ADD_DEVOIR_BEGIN,
  ADD_DEVOIR_SUCCESS,
  ADD_DEVOIR_ERR,

  DELETE_DEVOIR_BEGIN,
  DELETE_DEVOIR_SUCCESS,
  DELETE_DEVOIR_ERR,

  UPDATE_DEVOIR_BEGIN,
  UPDATE_DEVOIR_SUCCESS,
  UPDATE_DEVOIR_ERR,
} = actions;

const DevoirReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_DEVOIR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DEVOIR_SUCCESS:
      const updateArrayData = state.arrayData.map((item) => (item.id != data.id ? item : data));

      return {
        ...state,
        arrayData: updateArrayData,
        loading: false,
      };
    case UPDATE_DEVOIR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_DEVOIR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_DEVOIR_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_DEVOIR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_DEVOIR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_DEVOIR_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_DEVOIR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_DEVOIR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DEVOIR_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id != data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_DEVOIR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_DEVOIR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_DEVOIR_SUCCESS:
      return {
        ...state,
        arrayData: [...state.arrayData, data],
        loading: false,
      };
    case ADD_DEVOIR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default DevoirReducer;
