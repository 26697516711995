import React, { useState, lazy, Suspense, useEffect } from 'react';
import { Row, Col, Spin, Menu } from 'antd';
import { NoteNav, NoteWrapper } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { MailOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { viewChargeData } from '../../redux/charge/actionCreator';

const { SubMenu } = Menu;

const All = lazy(() => import('./overview/all'));

function Devoirs() {
  const [openKeys, setOpenKeys] = useState([]);

  const [selectedCharge, setSelectedCharge] = useState(null);
  const dispatch = useDispatch();
  const { charges } = useSelector((state) => {
    return {
      charges: state.ChargeReducer.arrayData,
    };
  });
  useEffect(() => {
    dispatch(viewChargeData());
  }, []);

  const handleChargeSelection = (data) => {
    setSelectedCharge(data);
  };
  const handleMenuClick = (e) => {
    setSelectedKey(e.key); // Update the selected key
  };
  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []); // Allow only one open key
  };

  return (
    <>
      <Main>
        <NoteWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={5} xl={7} lg={9} xs={24}>
              <div className="sidebar-card">
                <Cards headless>
                  <div className="note-sidebar-bottom">
                    <NoteNav>
                      <Menu mode="inline" openKeys={openKeys} onOpenChange={handleOpenChange}>
                        {charges.map((charge) => {
                          return (
                            <SubMenu
                              key={charge.id}
                              onTitleClick={() => handleChargeSelection({ charge: charge.id })}
                              title={
                                <span>
                                  <MailOutlined />
                                  <span> {charge.nom}</span>
                                </span>
                              }
                            >
                              {charge.sous_charges.map((sousCharge) => (
                                <Menu.Item
                                  style={{ marginLeft: '10%' ,width:"90%"}}
                                  key={sousCharge.id}
                                  onClick={() =>
                                    handleChargeSelection({ charge: charge.id, sousCharge: sousCharge.id })
                                  }
                                >
                                  {sousCharge.nom}
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          );
                        })}
                      </Menu>
                    </NoteNav>
                  </div>
                </Cards>
              </div>
            </Col>
            <Col xxl={19} xl={17} lg={15} xs={24}>
              <Suspense
                fallback={
                  <div className="spin">
                    <Spin />
                  </div>
                }
              >
                <All charges={charges} selectedCharge={selectedCharge} />
              </Suspense>
            </Col>
          </Row>
        </NoteWrapper>
      </Main>
    </>
  );
}

export default Devoirs;
