import React, { useState } from 'react';
import ClassStatistics from './moyenne/ClassStatistics';
import { Col, DatePicker, Row } from 'antd';
import LevelStatistics from './moyenne/LevelStatistics';
import RankByLevel from './moyenne/RankByLevel';
import RankByClass from './moyenne/RankByClass';
import LevelSuccessRate from './moyenne/LevelSuccessRate';
import ClassSuccessRate from './moyenne/ClassSuccessRate';
import moment from 'moment';

const { RangePicker } = DatePicker;


const MoyenneStatistics = () => {
  const [dates, setDates] = useState([moment().subtract(1, 'year'), moment()]);

  const handleChange = (values) => {
    setDates(values);
  };

  return (
    <div
      style={{
        margin: '25px',
      }}
    >
      <Row gutter={25}>
        <Col xxl={24} lg={24} sm={24} xs={24}>
          <RangePicker
            style={{ height: '37px', marginBottom: '20px' }}
            picker="month"
            defaultValue={[moment().subtract(1, 'year'), moment()]}
            onChange={handleChange}
          />
        </Col>
        <Col xxl={14} lg={14} sm={14} xs={24}>
          <ClassStatistics dates={dates} />
        </Col>
        <Col xxl={10} lg={10} sm={10} xs={24}>
          <LevelStatistics dates={dates} />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <LevelSuccessRate dates={dates} />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <ClassSuccessRate dates={dates} />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <RankByClass dates={dates} />
        </Col>
        <Col xxl={12} lg={12} sm={12} xs={24}>
          <RankByLevel dates={dates} />
        </Col>
      </Row>
    </div>
  );
};

export default MoyenneStatistics;
