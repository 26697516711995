import React, { useEffect, useState } from 'react';
import { Modal, Select, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { viewRankPerLevelStatisticsData } from '../../../redux/statistics/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';

const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    width: '30%',
  },
  {
    title: 'Nom',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Average Grade',
    dataIndex: 'average_grade',
    key: 'average_grade',
    width: '20%',
    render: (text) => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            color: text < 10 ? '#F32121' : '#4DC591',
            backgroundColor: text < 10 ? '#F321211e' : '#4DC5911e',
            padding: '5px 10px',
            borderRadius: '5px',
          }}
        >
          {text.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    ),
  },
];

const RankByLevel = ({ dates }) => {
  const [niveau, setNiveau] = useState('1');
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const { statistics } = useSelector((state) => {
    return {
      statistics: state.StatisticsReducer.ranksPerLevel,
    };
  });

  useEffect(() => {
    const startDate = dates[0]?.format('MM-YYYY');
    const endDate = dates[1]?.format('MM-YYYY');
    dispatch(viewRankPerLevelStatisticsData([startDate, endDate]));
  }, [dates]);

  useEffect(() => {
    if (statistics) {
      handleChangeData();
    }
  }, [statistics, niveau]);

  const handleChangeData = () => {
    const foundNiveau = statistics.find((item) => item.niveau == niveau);
    const foundStudents = foundNiveau ? foundNiveau.students : [];
    const traitedData = foundStudents
      .map((student) => ({
        ...student,
        name: `${student.prenom} ${student.nom}`,
        rank: student.rank[0],
      }))
      .slice(0, 5);
    console.log(foundNiveau);

    setData(foundNiveau ? traitedData : []);
  };

  return (
    <Cards headless>
      <div
        style={{
          height: '50vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: '500',
            marginBottom: '10px',
            color: '#333',
          }}
        >
          <div>Classement des élèves par niveau</div>
          <Select
            placeholder="Niveau"
            value={niveau}
            onChange={(e) => setNiveau(e)}
            style={{ height: '40px' }}
            options={[
              { value: '1', label: '1ère année' },
              { value: '2', label: '2ème année' },
              { value: '3', label: '3ème année' },
              { value: '4', label: '4ème année' },
              { value: '5', label: '5ème année' },
              { value: '6', label: '6ème année' },
            ]}
          />
        </div>
        <Table columns={columns} dataSource={data} pagination={false} showHeader={false} />
      </div>
    </Cards>
  );
};

export default RankByLevel;
